import { useContext } from "react";
import { AppContext } from "../context/index";
import * as amplitude from "@amplitude/analytics-browser";
import { sendQuestionData, sendUserFeedback } from "../utils";
export function useTrackSourceClicked() {
  const { instanceInfo, userProperties } = useContext(AppContext);
  /**
   * @param {string} type
   */
  return async (type) => {
    try {
      await amplitude
        .track("source_clicked", {
          "Session ID": userProperties["Session ID"],
          "Instance ID": process.env.REACT_APP_INSTANCE_ID,
          "Company Name": instanceInfo.companyName,
          "Instance Name": instanceInfo.name,
          "Source Type": type,
        })
        .promise();
    } catch (e) {}
  };
}
export function useTrackQuestionAsked() {
  const { instanceInfo, userProperties } = useContext(AppContext);
  /**
   * @param {import('./types').TrackQuestionProps} param0
   */
  return async ({ language, questionType, deviceDetails, question }) => {
    try {
      await amplitude
        .track("question_asked", {
          "Session ID": userProperties["Session ID"],
          Language: language,
          "Instance ID": process.env.REACT_APP_INSTANCE_ID,
          "Company Name": instanceInfo.companyName,
          "Instance Name": instanceInfo.name,
          "Question Type": questionType,
          "Device Details": deviceDetails,
          "Question Value": question,
        })
        .promise();
    } catch (e) {}
  };
}
export function useTrackEscalation() {
  const { userProperties, instanceInfo } = useContext(AppContext);
  /**
   *@param {string} type
   */
  return async (type) => {
    try {
      await amplitude
        .track("escalation_triggered", {
          "Session ID": userProperties["Session ID"],
          "Instance ID": process.env.REACT_APP_INSTANCE_ID,
          "Escalation Type": type,
          "Company Name": userProperties["Company Name"],
          "Instance Name": instanceInfo.name,
        })
        .promise();
    } catch (e) {}
  };
}
export function useSendQuestionData() {
  const { setUserProperties, instanceInfo } = useContext(AppContext);
  return async (
    userInput,
    answer,
    sourceDocument = "",
    sourcePages = [],
    sessionId = null,
    conversationId = null,
    error = false,
    emptyAnswer = true,
    otherQuestionsEnabled = false
  ) => {
    setUserProperties((prev) => {
      const newDevices = Array.from(
        new Set([
          ...prev["Device Details"],
          otherQuestionsEnabled ? "general" : userInput.device,
        ])
      );
      try {
        const identify = new amplitude.Identify()
          .add("Questions Asked", 1)
          .set("Device Details", newDevices);
        amplitude.identify(identify);
      } catch (e) {}
      try {
        amplitude.track("response_provided", {
          "Session ID": prev["Session ID"],
          Language: userInput.language,
          "Instance ID": process.env.REACT_APP_INSTANCE_ID,
          "Company Name": instanceInfo.companyName,
          "Instance Name": instanceInfo.name,
          "Question Type": "Product Question",
          "Device Details": otherQuestionsEnabled
            ? "General"
            : {
                Manufacturer: userInput.manufacturer,
                Device: userInput.device,
                DeviceType: userInput.deviceType,
              },
          "Question Value": userInput.problem,
          "Response Value": answer,
          "Response Source": sourceDocument,
          "Empty Answer": emptyAnswer ? "Yes" : "No",
        });
      } catch (e) {}

      return {
        ...prev,
        "Questions Asked": prev["Questions Asked"] + 1,
        "Device Details": newDevices,
      };
    });
    return sendQuestionData(
      userInput,
      answer,
      sourceDocument,
      sourcePages,
      sessionId,
      conversationId,
      error,
      emptyAnswer,
      otherQuestionsEnabled
    );
  };
}
export function useSendUserFeedback() {
  const { instanceInfo } = useContext(AppContext);
  return async (recordId, positive, conversationId, feedback) => {
    try {
      amplitude.track("user_feedback", {
        "Session ID": instanceInfo.userProperties["Session ID"],
        "Instance ID": process.env.REACT_APP_INSTANCE_ID,
        "Company Name": instanceInfo.companyName,
        "Instance Name": instanceInfo.name,
        "Record ID": recordId,
        "Feedbacl Type": positive ? "Up" : "Down",
        "Feedback Note": feedback,
      });
    } catch (e) {}
    return sendUserFeedback(recordId, positive, conversationId, feedback);
  };
}
export function useTrackNewQuestionClicked() {
  const { instanceInfo, userProperties } = useContext(AppContext);
  return () => {
    try {
      amplitude.track("new_question_clicked", {
        "Session ID": userProperties["Session ID"],
        "Instance ID": process.env.REACT_APP_INSTANCE_ID,
        "Company Name": instanceInfo.companyName,
        "Instance Name": instanceInfo.name,
      });
    } catch (e) {}
  };
}
