import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import axios from "axios";
import Installer from "./components/Installer";
import Customer from "./components/Customer";
import DemoWebsite from "./components/DemoWebsite";
import Chatbot from "./components/Chatbot";
import Loading from "./components/Loading";
import { defaultInstance } from "./utils/constants";
import { AppContext } from "./context";
import NotFound from "./components/NotFound";
import ErrorPage from "./components/ErrorPage";
import FavIcon from "./assets/bubble.svg";
import * as amplitude from "@amplitude/analytics-browser";
import {
  getApiURL,
  initializeHotjarTracker, normalizeColor,
  initializeAmplitudeTracker,
} from "./utils";
function App() {
  const sessionId = React.useMemo(() => {
    return crypto.randomUUID();
  }, []);

  const [userProperties, setUserProperties] = useState({
    "Session ID": sessionId,
    Language: "",
    "Feedback Count": 0,
    "Escalation Count": 0,
    "Device Details": [],
    "Questions Asked": 0,
  });

  const [instanceInfo, setInstanceInfo] = useState(defaultInstance);
  const [originalInstanceInfo, setOriginalInstanceInfo] =
    useState(defaultInstance);
  const [isLoading, setIsLoading] = useState(true);
  const [feedbackIsAdded, setFeedbackIsAdded] = useState(false);
  const [typeOfDemo, setTypeOfDemo] = useState("");
  const [error, setError] = useState(null);
  const [view, setView] = useState("Installer");

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = React.useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );
  React.useEffect(() => {
    initializeHotjarTracker();
    initializeAmplitudeTracker().then(async () => {
      await amplitude.identify(sessionId, userProperties).promise();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeInstanceLanguage = (language) => {
    setInstanceInfo({
      ...instanceInfo,
      mainLanguage: language,
    });
    setUserProperties({
      ...userProperties,
      Language: language,
    });
  };

  const handleViewChange = useCallback(
    (e) => {
      const newView = e.target.checked ? "Customer" : "Installer";
      setView(newView);
      const preViewPath = location.pathname.startsWith("/testing")
        ? "/testing"
        : "";
      const viewPath =
        preViewPath + (newView === "Customer" ? "/customer" : "/installer");
      handleNavigation(viewPath);
    },
    [location.pathname, setView, handleNavigation]
  );

  useEffect(() => {
    if (location.pathname.startsWith("/demo")) {
      // Extract parameters for demo route
      const queryParams = new URLSearchParams(location.search);

      // Extract query parameters
      const instanceName = queryParams.get("instanceName");
      const website = queryParams.get("website");
      const logoUrl = queryParams.get("logo");
      const questions = queryParams.get("questions");

      // Function to validate URL
      const isValidUrl = (urlString) => {
        try {
          return Boolean(new URL(urlString));
        } catch (_) {
          return false;
        }
      };

      // Validate logo URL
      const validLogoUrl = logoUrl && isValidUrl(logoUrl) ? logoUrl : null;

      // Set instance info
      setInstanceInfo((prevInfo) => ({
        ...prevInfo,
        instanceName: instanceName || prevInfo.instanceName,
        website: website || prevInfo.website,
        logo: validLogoUrl || prevInfo.logo,
        questions: questions || prevInfo.questions,
      }));
      setTypeOfDemo("Demo");
      setIsLoading(false);
      return () => {};
    }

    setIsLoading(true);
    const instanceId = process.env.REACT_APP_INSTANCE_ID;

    // Check if instanceId is not available
    if (!instanceId) {
      setInstanceInfo(defaultInstance);
      setIsLoading(false);
      return;
    }
    const api = `${getApiURL()}/instance`;

    const sendData = {
      instanceId: instanceId,
    };

    axios
      .get(api, {
        params: sendData,
      })
      .then((response) => {
        setInstanceInfo(response.data);
        setUserProperties({
          ...userProperties,
          Language: response.data.mainLanguage || "English",
        });
        const identify = new amplitude.Identify().set(
          "Language",
          response.data.mainLanguage || "English"
        );
        amplitude.groupIdentify(
          "Company",
          response.data.companyId || response.data.instanceId,
          identify
        );
        amplitude.identify(identify);
        setOriginalInstanceInfo(response.data);
        setTypeOfDemo(response.data.typeOfDemo || "Both");
        setView(
          response.data.typeOfDemo === "Both"
            ? "Installer"
            : response.data.typeOfDemo
        );
        const pageLinks = document.getElementsByTagName("link");
        const pageLink = Array.from(pageLinks).find((link) => {
          return link.rel === "icon";
        });
        if (pageLink && response?.data?.favIcon) {
          pageLink.href = response.data.favIcon;
        } else {
          pageLink.href = FavIcon;
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [location.pathname, location.search]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === "/customer") {
      setView("Customer");
    } else if (currentPath === "/installer") {
      setView("Installer");
    }
  }, [location.pathname]);

  useEffect(() => {
    // Redirect to a valid route based on typeOfDemo
    if (
      typeOfDemo === "Installer" &&
      location.pathname !== "/" &&
      location.pathname !== "/testing"
    ) {
      window.history.replaceState(null, "", "/");
    } else if (
      typeOfDemo === "Customer" &&
      location.pathname !== "/" &&
      location.pathname !== "/testing"
    ) {
      window.history.replaceState(null, "", "/");
    } else if (typeOfDemo === "Both" && location.pathname === "/") {
      window.history.replaceState(null, "", "/installer");
    }
  }, [typeOfDemo, location.pathname]);

  const demoProps = useMemo(() => {
    return {
			logo: instanceInfo.logo,
			typeOfDemo,
			view,
			handleViewChange,
			feedbackIsAdded,
			variables: instanceInfo,
			setFeedbackIsAdded,
			mainColor:
				normalizeColor(instanceInfo.mainColor) === "#ffffff"
    ? "#8745EF"
    : instanceInfo.mainColor,
		};
  }, [
    instanceInfo,
    view,
    typeOfDemo,
    handleViewChange,
    feedbackIsAdded,
    setFeedbackIsAdded,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorPage statusCode={error.response?.status} message={error.message} />
    );
  }

  return (
    <AppContext.Provider
      value={{
        instanceInfo,
        changeInstanceLanguage,
        originalInstanceInfo,
        userProperties,
        setUserProperties,
      }}
    >
      <div>
        <link
          rel="preload"
          href="../public/fonts/Poppins-Regular.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="../public/fonts/Poppins-Medium.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="../public/fonts/Poppins-SemiBold.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="../public/fonts/Poppins-Bold.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <Routes>
          {typeOfDemo === "Installer" && (
            <>
              <Route path="/" element={<Installer {...demoProps} />} />
              <Route
                path="/testing"
                element={<Installer {...demoProps} isTesting />}
              />
            </>
          )}
          {typeOfDemo === "Customer" && [
            <Route path="/" element={<Customer {...demoProps} />} />,
            <Route
              path="/testing"
              element={<Customer {...demoProps} isTesting />}
            />,
          ]}
          {typeOfDemo === "Both" && (
            <>
              <Route path="/" element={<Navigate to="/installer" />} />
              <Route
                path="/testing"
                element={<Navigate to="/testing/installer" />}
              />
              <Route path="/installer" element={<Installer {...demoProps} />} />
              <Route
                path="/testing/installer"
                element={<Installer {...demoProps} isTesting />}
              />
              <Route path="/customer" element={<Customer {...demoProps} />} />
              <Route
                path="/testing/customer"
                element={<Customer {...demoProps} isTesting />}
              />
            </>
          )}
          <Route path="/chatbot" element={<Chatbot {...demoProps} />} />
          <Route
            path="/chatbot/testing"
            element={<Chatbot {...demoProps} isTesting />}
          />
          <Route path="/demo" element={<DemoWebsite />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </AppContext.Provider>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
