import { useContext, useEffect } from "react";
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { ReactComponent as EmailIcon } from "../assets/mail.svg";
import { useUIText } from "../hooks";
import * as amplitude from "@amplitude/analytics-browser";
import { AppContext } from "../context";
const NoResults = ({ txt, protectionSystem = false }) => {
  const { setUserProperties } = useContext(AppContext);
  useEffect(() => {
    setUserProperties((prev) => {
      return {
        ...prev,
        "Escalation Count": prev["Escalation Count"] + 1,
      };
    });
    const identify = new amplitude.Identify().add("Escalation Count", 1);
    amplitude.identify(identify);
  }, []);
  const uiText = useUIText();
  return (
    <div className={txt === "" ? "no-results" : "no-results custom"}>
      {txt === "" ? (
        <>
          <div className="no-results__icon">
            <InfoIcon />
          </div>
          <div className="no-results__text">
            <div className="no-results__text-title">
              {protectionSystem
                ? uiText?.noResults?.protectionAnswer
                : uiText?.noResults?.title}
            </div>
            <div className="no-results__text-subtitle">
              {uiText?.noResults?.subtitle}
            </div>
          </div>
        </>
      ) : (
        <div
          className="feedback-instructions"
          dangerouslySetInnerHTML={{ __html: txt }}
        />
      )}
    </div>
  );
};

export default NoResults;
